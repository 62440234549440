import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/Seo'
import Layout from '../components/Layout'
import Container from '../components/Container'
import Section from '../components/Section'
import Flex from '../components/Flex'

import Hero from '../components/Hero'
import CtaBox from '../components/CtaBox'
import ServiceIcons from '../components/ServiceIcons'
import InfoBox from '../components/InfoBox'
import Clients from '../components/Clients'
import TextBlock from '../components/TextBlock'
import Slider from '../components/Slider'
import ApplyInfo from '../components/ApplyInfo'

import { Typo2, Typo3, Typo4, Typo6 } from '../components/Typography'
import ButtonLink from '../components/ButtonLink/index'
import {
  ButtonLinkType,
  ButtonLinkSize,
  ButtonLinkWidth,
} from '../components/ButtonLink/styles'

import { HEADER_TYPES } from '../constants/headerTypes'
import { HEADER_BUTTON_TYPES } from '../constants/headerButtonTypes'

import { qualifiedProjects } from '../mocks/qualifiedProjectsMock'
import { jsMindsLike } from '../mocks/jsMindsLikeMock'
import { levelCareer } from '../mocks/levelCareerMock'
import { clients } from '../mocks/clientsMock'
import { developers } from '../mocks/developersMock'
import { whoCan, become } from '../mocks/applyInfoMock'

import ReactIcon from '../assets/svg/ReactIcon'
import ReactNativeIcon from '../assets/svg/ReactNativeIcon'
import GatsbyIcon from '../assets/svg/GatsbyIcon'

const PageproIcon = require('../assets/images/svg/pagepro-dark-logo.svg') as string

const DevelopersPage = () => (
  <Layout
    headerType={HEADER_TYPES.dark}
    headerButtonType={HEADER_BUTTON_TYPES.developers}
  >
    <SEO title="Become a JS Mind and work on the hottest JavaScript projects, remotely." />
    <Container bg="darkNavy">
      <Hero withBgMask pt={['2rem', '218px']} pb={['2rem', '150px']}>
        <CtaBox
          title={
            <Typo2 as="h1" fontWeight="bold">
              Work on <span>React Projects</span>
              <br /> You (truly) like.
            </Typo2>
          }
          description={
            <Typo4 fontWeight="bold" color="white">
              All over Europe. 100% remote
            </Typo4>
          }
          action={
            <ButtonLink
              className="button"
              type={ButtonLinkType.primary}
              size={ButtonLinkSize.medium}
              href="https://jsminds.recruitee.com/"
            >
              Join us!
            </ButtonLink>
          }
          media={
            <ServiceIcons
              color="white"
              items={[
                {
                  id: 1,
                  icon: <ReactIcon />,
                },
                { id: 2, icon: <ReactNativeIcon /> },
                {
                  id: 3,
                  icon: <GatsbyIcon />,
                },
              ]}
            />
          }
          direction={['column', 'row']}
          pbContent={['2rem', '0']}
        />
      </Hero>
    </Container>
    <Container
      bg="lightGrey"
      pt={['2.875rem', '4.5rem']}
      pb={['2.875rem', '4.5rem']}
    >
      <Section
        pbHead={['1rem', '1rem']}
        pbDesc={['3rem', '3.5rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Choose from <span>Qualified Projects</span> only
          </Typo3>
        }
        desc={
          <Typo6 color="darkGrey">
            All the projects in JSMinds come only from qualified companies
            <br />
            with a proven record of successful work with remote developers.
          </Typo6>
        }
      >
        <Flex flexDirection={['column', 'row']}>
          {qualifiedProjects.map(
            ({ heading, src, alt, description, additionalDescription }) => (
              <Flex flex={['', '1']} pb={['1rem', '']} key={heading}>
                <Container width={['100%', '100%']} pr={['', '3.7rem']}>
                  <InfoBox {...{ heading, src, alt }}>
                    <Typo6>{description}</Typo6>
                    {additionalDescription && (
                      <Typo6>{additionalDescription}</Typo6>
                    )}
                  </InfoBox>
                </Container>
              </Flex>
            )
          )}
        </Flex>
      </Section>
    </Container>
    <Container
      bg="tiber"
      pt={['2.875rem', '3.5rem']}
      pb={['2.875rem', '2.5rem']}
    >
      <Section
        pbHead={['3rem', '3.5rem']}
        heading={
          <Typo3 as="h2" color="white" fontWeight="bold" textAlign="left">
            What <span>Other JS Minds</span> Like?
          </Typo3>
        }
      >
        <Flex flexDirection={['column', 'row']}>
          {jsMindsLike.map(
            ({ heading, src, alt, description, additionalDescription }) => (
              <Flex flex={['', '1']} pb="1rem" key={heading}>
                <Container width="100%" pr={['', '3.7rem']}>
                  <InfoBox light {...{ heading, src, alt }}>
                    <Typo6 color="white">{description}</Typo6>
                    {additionalDescription && (
                      <Typo6 color="white">{additionalDescription}</Typo6>
                    )}
                  </InfoBox>
                </Container>
              </Flex>
            )
          )}
        </Flex>
      </Section>
    </Container>

    <Container pt={['2.875rem', '3.5rem']} pb={['2.875rem', '2.5rem']}>
      <Section
        pbHead={['2rem', '']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Level Up <span>Your Career</span>
          </Typo3>
        }
      >
        <Flex flexDirection={['column', 'row']}>
          {levelCareer.map(
            ({ heading, headingIcon, description, additionalDescription }) => (
              <Flex flex={['', '1']} key={heading}>
                <Container width="100%" pr={['', '3.7rem']}>
                  <InfoBox heading={heading} headIconSrc={headingIcon}>
                    <Typo6>{description}</Typo6>
                    {additionalDescription && (
                      <Typo6>{additionalDescription}</Typo6>
                    )}
                  </InfoBox>
                </Container>
              </Flex>
            )
          )}
        </Flex>
      </Section>
    </Container>
    <Container bg="lightGrey">
      <ApplyInfo whoCan={whoCan} become={become} />
    </Container>
    <Container pt={['2.875rem', '4.5rem']} pb={['2.875rem', '4.5rem']}>
      <Section
        pbHead={['2rem', '2.875rem']}
        heading={
          <Typo3 as="h2" fontWeight="bold" textAlign="left">
            Our developers worked for:
          </Typo3>
        }
      >
        <Clients items={clients} />
      </Section>
    </Container>
    <Container
      overflow="hidden"
      pt={['2.875rem', '4.5rem']}
      pb={['2.875rem', '4.5rem']}
    >
      <Section hasCustomInner>
        <Slider items={developers} slideBackground="lightGrey" />
      </Section>
    </Container>
    <Container
      bg="lightGrey"
      pt={['2.875rem', '4.5rem']}
      pb={['2.875rem', '4.5rem']}
    >
      <Section>
        <Flex flexDirection={['column', 'row']}>
          <Flex flex={['', '1']} pb={['3rem', '0']} width="100%">
            <Container width="100%" pr={['', '8rem']}>
              <TextBlock
                head={
                  <Typo3 as="h3" fontWeight="bold" textAlign="left">
                    Who is behind JS Minds?
                  </Typo3>
                }
                body={
                  <Typo6>
                    JSMind been made by Pagepro - a React Rebels and JavaScript
                    Superheroes that are delivering products and training
                    hundreds of quality developers already for more than 11
                    years.
                  </Typo6>
                }
              />
            </Container>
          </Flex>
          <Flex flex={['', '1']} pb={['1rem', '0']} width="100%">
            <Container pr={['', '3.7rem']}>
              <TextBlock src={PageproIcon} alt="Pagepro Logo" />
            </Container>
          </Flex>
        </Flex>
      </Section>
    </Container>
  </Layout>
)

export default DevelopersPage
