import { IIconListItem } from '../components/ApplyInfo'

export const whoCan: IIconListItem['list'] = [
  {
    id: 1,
    title: 'Only Experienced Developers',
    description:
      'We require at least 2 years of commercial experience in React.',
  },
  {
    id: 2,
    title: 'Fluent English',
    description:
      'Our projects come from all around Europe and the World. This is why fluent English is a must-have. ',
  },
  {
    id: 3,
    title: 'Ambitious',
    description:
      'Our goal is to create the best React community in the World. We seek people who can proactively help us to grow together',
  },
]

export const become: IIconListItem['list'] = [
  {
    id: 1,
    title: 'Apply!',
    description:
      'To apply, fill in the form with all required data. Try to leave no empty fields. This will definitely fasten the recruitment process.',
  },
  {
    id: 2,
    title: 'Online Task',
    description:
      'After reviewing your resume, you will receive an online task tailor-made to your presented skills.',
  },
  {
    id: 3,
    title: 'Interview',
    description:
      'If the task been done well, we will invite you for the online interview, in which we will have the opportunity to meet much better!',
  },
]
