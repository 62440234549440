import { IInfoboxItem } from '../components/Section'

const ReactIcon = require('../assets/images/svg/react.svg') as string
const TrophyIcon = require('../assets/images/svg/trophy.svg') as string
const ClockIcon = require('../assets/images/svg/clock.svg') as string

export const jsMindsLike: IInfoboxItem['items'] = [
  {
    heading: 'React Niche',
    headingIcon: '',
    description:
      'JS Minds is a place full of trusted and highly qualifieddevelopers that are getting constantly connected to new job opportunities, as well as to each other.',
    additionalDescription: '',
    src: ReactIcon,
    alt: 'Rocket',
  },
  {
    heading: 'Great Companies',
    headingIcon: '',
    description:
      'Thanks to our qualifying process, you can access remoteopportunities from the hottest startups from London, Berlin, and Amsterdam.',
    additionalDescription: '',
    src: TrophyIcon,
    alt: 'Trophy',
  },
  {
    heading: 'Flexible Contracts',
    headingIcon: '',
    description: `This is the place for projects you truly like.`,
    additionalDescription:
      'From full-time positions to part-time roles. They choose what’s best for their skills, interests, and lifestyle.',
    src: ClockIcon,
    alt: 'Clock',
  },
]
