import React from 'react'

import {
  ApplyInfoContainerStyled,
  ApplyInfoStyled,
  ApplyInfoLeftColumnStyled,
  ApplyInfoRightColumnStyled,
  ApplyInfoCardStyled,
} from './styles'
import Section from '../Section/index'
import Inner from '../Inner'
import { Typo3 } from '../Typography/index'
import ButtonLink from '../ButtonLink/index'
import IconList from '../IconList/index'
import {
  ButtonLinkType,
  ButtonLinkSize,
  ButtonLinkWidth,
} from '../ButtonLink/styles'
import { IIconList } from '../../interfaces/IIconList'

const waterMark = require('../../assets/images/svg/logo-watermark-small.svg') as string

export interface IIconListItem {
  list: IIconList[]
}

interface IProps {
  whoCan: IIconList[]
  become: IIconList[]
}

const ApplyInfo: React.FC<IProps> = ({ whoCan, become }) => (
  <ApplyInfoContainerStyled>
    <Inner alt>
      <ApplyInfoStyled>
        <ApplyInfoLeftColumnStyled backgroundImage={`url(${waterMark})`}>
          <ApplyInfoCardStyled
            pt={['2.875rem', '4.5rem']}
            pb={['2.5rem', '2.75rem']}
          >
            <Section
              pbHead={['3rem', '3.375rem']}
              heading={
                <Typo3 as="h2" fontWeight="bold" textAlign="left">
                  Who can apply?
                </Typo3>
              }
            >
              <IconList list={whoCan} />
            </Section>
          </ApplyInfoCardStyled>
        </ApplyInfoLeftColumnStyled>
        <ApplyInfoRightColumnStyled bg="tiber">
          <ApplyInfoCardStyled
            pt={['2.875rem', '4.5rem']}
            pb={['2.5rem', '2.75rem']}
          >
            <Section
              pbHead={['3rem', '3.375rem']}
              pbBody={['2rem', '2.8rem']}
              heading={
                <Typo3 as="h2" fontWeight="bold" textAlign="left" color="white">
                  Become a JS Mind
                </Typo3>
              }
              action={
                <ButtonLink
                  className="button"
                  type={ButtonLinkType.primary}
                  size={ButtonLinkSize.medium}
                  fluid={ButtonLinkWidth.full}
                  href="https://jsminds.recruitee.com/"
                >
                  Join Us
                </ButtonLink>
              }
            >
              <IconList list={become} isOrdered isDark />
            </Section>
          </ApplyInfoCardStyled>
        </ApplyInfoRightColumnStyled>
      </ApplyInfoStyled>
    </Inner>
  </ApplyInfoContainerStyled>
)

export default ApplyInfo
