import { IInfoboxItem } from '../components/Section'

const RocketIcon = require('../assets/images/svg/rocket.svg') as string
const TeamIcon = require('../assets/images/svg/team.svg') as string
const IdeasIcon = require('../assets/images/svg/ideas.svg') as string

export const qualifiedProjects: IInfoboxItem['items'] = [
  {
    heading: 'The Hottest Tech Stack',
    headingIcon: '',
    description:
      'We choose the project only with the latest and the most interesting tech stack in the IT/startups world.',
    additionalDescription: '',
    src: RocketIcon,
    alt: 'Rocket',
  },
  {
    heading: 'Experienced Teams Only',
    headingIcon: '',
    description:
      ' We carefully select experienced teams with a proven record of successful remote works.',
    additionalDescription: '',
    src: TeamIcon,
    alt: 'Team',
  },
  {
    heading: 'Challenging Ideas',
    headingIcon: '',
    description:
      'JSMinds are ambitious. And the best way to learn and grow is by working on interesting and challenging projects.',
    additionalDescription: '',
    src: IdeasIcon,
    alt: 'Ideas',
  },
]
