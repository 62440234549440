import styled from 'styled-components'

import { media } from '../../common/MediaQueries'
import Container from '../Container'
import Inner from '../Inner'

const ApplyInfoContainerStyled = styled.div`
  width: 100%;
`

const ApplyInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media ${media.phone} {
    flex-direction: row;
  }
`

const ApplyInfoLeftColumnStyled = styled(Container)`
  width: 100%;
  background-position: 90% 120%;
  background-size: 14.125rem;
  background-repeat: no-repeat;

  @media ${media.phone} {
    max-width: 51%;
    flex-basis: 51%;
    background-position: 150% 3.125rem;
    background-size: 20.375rem;

    ${Inner} {
      padding-left: 0;
    }
  }
`

const ApplyInfoRightColumnStyled = styled(Container)`
  width: 100%;

  @media ${media.phone} {
    max-width: 49%;
    flex-basis: 49%;

    ${Inner} {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
`

const ApplyInfoCardStyled = styled(Container)``

export {
  ApplyInfoContainerStyled,
  ApplyInfoStyled,
  ApplyInfoLeftColumnStyled,
  ApplyInfoRightColumnStyled,
  ApplyInfoCardStyled,
}
