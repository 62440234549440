import styled, { css } from 'styled-components'

import { media } from '../../common/MediaQueries'

const IconListItemStyled = styled.li`
  position: relative;
  padding-left: 3.75rem;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0.625rem;
    left: 0;
    width: 1.875rem;
    height: 0.1875rem;
    background-color: ${({ theme }) => theme.colors.red};
  }

  @media ${media.phone} {
    max-width: 20.625rem;
  }
`

const IconListItemContentStyled = styled.div`
  position: relative;
`

const IconListItemHeadStyled = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 0.75rem;
`

const IconListItemBodyStyled = styled.div`
  color: ${({ theme }) => theme.colors.mediumGrey};
`

const IconListStyled = styled.ul`
  ${({ isOrdered, theme }) =>
    isOrdered &&
    css`
      ${IconListItemStyled} {
        &::before {
          content: attr(data-id);
          top: -0.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.0625rem;
          height: 2.0625rem;
          border-radius: 50%;
          color: ${theme.colors.white};
          font-weight: ${theme.fontWeight.bold};
        }
      }
    `}

  ${({ isDark, theme }) =>
    isDark &&
    css`
      ${IconListItemHeadStyled} {
        color: ${theme.colors.white};
      }
      ${IconListItemBodyStyled} {
        color: ${theme.colors.lightGrey2};
      }
    `}
`

export {
  IconListStyled,
  IconListItemStyled,
  IconListItemContentStyled,
  IconListItemHeadStyled,
  IconListItemBodyStyled,
}
