import React from 'react'
import {
  IconListStyled,
  IconListItemStyled,
  IconListItemContentStyled,
  IconListItemHeadStyled,
  IconListItemBodyStyled,
} from './styles'
import { Typo1, Typo6 } from '../Typography/index'

import { IIconList } from '../../interfaces/IIconList'

interface IProps {
  list: IIconList[]
  isOrdered?: boolean
  isDark?: boolean
}

const IconList: React.FC<IProps> = ({ list, isOrdered, isDark }) => (
  <IconListStyled isOrdered={isOrdered} isDark={isDark}>
    {list.map(({ id, title, description }) => (
      <IconListItemStyled key={id} data-id={id}>
        <IconListItemContentStyled>
          <IconListItemHeadStyled>
            <Typo1 fontWeight="bold">{title}</Typo1>
          </IconListItemHeadStyled>
          <IconListItemBodyStyled>
            <Typo6>{description}</Typo6>
          </IconListItemBodyStyled>
        </IconListItemContentStyled>
      </IconListItemStyled>
    ))}
  </IconListStyled>
)

export default IconList
