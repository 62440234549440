import { IInfoboxItem } from '../components/Section'

const CheckIcon = require('../assets/images/svg/check.svg') as string

export const levelCareer: IInfoboxItem['items'] = [
  {
    heading: 'One-time Easy Application',
    headingIcon: CheckIcon,
    description:
      'Once you pass JS Minds recruitment, your application will stay here for as long as you want it.',
    additionalDescription: 'You can also update it anytime you want.',
    src: '',
    alt: '',
  },
  {
    heading: 'Career Feedback',
    headingIcon: CheckIcon,
    description:
      "Even if you won't pass the JS Minds recruitment, but we’ close to do that, we will send you useful feedback on directions you should take to become a better React developer.",
    additionalDescription: '',
    src: '',
    alt: '',
  },
  {
    heading: 'Experts Support',
    headingIcon: CheckIcon,
    description:
      'Being JS Minds member means being part of one of the most valuable React community. You can count on our help and support during harder times and tough projects.',
    additionalDescription: 'You are one of us.',
    src: '',
    alt: '',
  },
]
